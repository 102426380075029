<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<div slot="header" class="calendar-head">
				<div class="title">{{ $t('seller.campaign.calendar') }}</div>
				<div class="time">
					<i class="el-icon-arrow-left" @click="handleCalendar(-3)"></i>
					<span>{{ calendarTime | parseTime('{y}年{m}月')}} ~ {{ calendarDatePicker(calendarTime, 2) | parseTime('{y}年{m}月')}}</span>
					<i class="el-icon-arrow-right" @click="handleCalendar(3)"></i>
				</div>
				<div class="next">
					<router-link :to="{ name: 'campaignCalendar' }" class="link">{{ $t('seller.actions.details') }}</router-link>
				</div>
			</div>
			<div class="calendar-main">
				<div class="calendar-panel" v-for="item in calendarList" :key="item.month">
					<div class="panel-head">
						<div class="month">{{ parseInt(item.month) }}{{ $t('seller.unit.month') }}</div>
						<div class="number">{{ item.list.length }}</div>
					</div>
					<el-scrollbar class="panel-main">
						<div class="panel-list">
							<div class="item" v-for="child in item.list" :key="child.activity_id" :class="{active:child.a_state < 6}">
								<div class="text">
									<el-tag type="info" size="mini"># {{ child.label_name }}</el-tag>
									<el-tag :type="child.a_state | typeFilter({1:'success',2: 'success',3: 'success',4:'warning',5:'danger',6:'info'})" size="mini">
										<svg-icon :icon-class="child.a_state | typeFilter({1:'medal_fill',2: 'medal_fill',3: 'medal_fill',4:'broadcast_fill',5:'hot_fill',6:'activity_fill'})"></svg-icon>{{ child.a_state | typeFilter($t('seller.options.campaignStatusTags')) }}
									</el-tag>
								</div>
								<div class="name"><router-link :to="{ name: 'campaignDetail', params: {id: child.activity_id }}">{{ child.name }}</router-link></div>
							</div>
							<div v-if="!item.list.length > 0" class="empty">{{ $t('seller.campaign.tipsText2') }}</div>
						</div>
					</el-scrollbar>
				</div>
			</div>
		</el-card>
		<el-card class="seller-card card-tabs">
			<el-tabs slot="header" v-model="activeName" class="seller-tabs">
				<el-tab-pane :label="$t('seller.campaign.all')" name="all"></el-tab-pane>
			</el-tabs>
			<el-form inline size="medium">
				<el-form-item :label="$t('seller.screen.campaignName') + ':'">
					<el-input v-model="pageQuery.name" :placeholder="$t('seller.placeholder.enterSearch')" @keyup.enter.native="handleSearch">
					</el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.screen.goodsId') + ':'">
					<el-input v-model="pageQuery.goods_id" :placeholder="$t('seller.placeholder.enterSearch')" @keyup.enter.native="handleSearch">
					</el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.screen.campaignTag') + ':'">
					<el-select v-model="pageQuery.activity_label_id" clearable>
						<el-option v-for="item in tagsOptions" :key="item.activity_label_id" :label="item.label_name"
							:value="item.activity_label_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('seller.campaign.signUpTime') + ':'">
					<el-date-picker v-model="signuptime" type="datetimerange" :range-separator="$t('seller.unit.to')"
						:start-placeholder="$t('seller.placeholder.startDate')" :end-placeholder="$t('seller.placeholder.endDate')"
						value-format="timestamp" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleSearch">{{$t('seller.actions.search')}}</el-button>
					<el-button type="seller__clear" icon="el-icon-brush" @click="handleSearchClear">{{$t('seller.actions.clearQuery')}}</el-button>
				</el-form-item>
			</el-form>
			<div class="activity-list">
				<div class="item" v-for="item in campaignList" :key="item.activity_id">
					<div class="item-wrap">
						<div class="item-head"><el-tag type="info" size="small">{{ item.label_name }}</el-tag></div>
						<div class="item-main">
							<div class="name">{{ item.name }}</div>
							<!-- <div class="text" v-if="item.describe">{{ item.describe }}</div> -->
							<div class="time">{{ $t('seller.campaign.shopSignUpTime') }}<span>{{ item.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ item.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span></div>
							<!-- <template v-else>
								<div class="time">预热时间<span>{{ item.warm_up_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ item.warm_up_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }} 预热{{ timeDifference(item.warm_up_start_time, item.warm_up_end_time) }}天</span></div>
								<div class="time">活动时间<span>{{ item.sales_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ item.sales_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }} 售卖{{ timeDifference(item.sales_start_time, item.sales_end_time) }}天</span></div>
							</template> -->
						</div>
					</div>
					<div class="item-bottom">
						<div class="status" v-if="item.status === 1"><span>{{ $t('seller.campaign.tipsText3') }}</span></div>
						<div class="status" v-else-if="item.status === 2">
							<span v-if="item.is_store_join !== 1" :class="{ primary: item.a_state === 2 }"><i class="el-icon-time"></i><strong>{{ item.a_state !== 2 ? 0 : 1 | typeFilter($t('seller.options.signUp')) }}</strong></span>
							<span v-else class="primary"><i class="el-icon-time"></i><strong>{{ $t('seller.campaign.hasSignUp') }}</strong></span>
							<span v-if="item.a_state === 2">{{ signUpStatus(item.s_enroll_start_time, item.s_enroll_end_time) }}</span>
							<span v-else>{{ item.a_state | typeFilter($t('seller.options.campaignStatusText')) }}</span>
						</div>
						<div class="status" v-else-if="item.status === 3">{{ $t('seller.campaign.tipsText4') }}</div>
						<router-link :to="{ name: 'campaignDetail', params: {id: item.activity_id }}">
							<el-button type="primary" size="medium" round v-if="item.a_state === 2 && item.is_store_join === 0">{{ $t('seller.actions.registerNow') }}</el-button>
							<el-button size="medium" round v-else>{{ $t('seller.actions.viewDetails') }}</el-button>
						</router-link>
					</div>
				</div>
			</div>
			<el-empty v-if="!campaignList.length > 0" :description="$t('seller.empty.nodata')"></el-empty>
			<div class="activity-bottom">
				<el-button v-if="moreFlag" type="more" size="medium" round v-loading="listLoading" @click="handleViewMore">{{ $t('seller.actions.viewMore') }}</el-button>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { datePicker } from "@/utils/index"
import { fetchCampaignCalendar, fetchCampaignList, fetchCampaignTags } from '@/api/seller/market'
const queryDefault = {
	name: '',
	activity_label_id: ''
}
export default {
	data() {
		return {
			calendarTime: new Date(new Date().getFullYear(),new Date().getMonth()-1, 1),
			calendarList: [],
			activeName: 'all',
			tagsOptions: [],
			signuptime: '',
			pageQuery: Object.assign({},queryDefault),
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			listLoading: false,
			campaignList: [],
			moreFlag: true
		}
	},
	created () {
		this.getData()
		this.getList()
	},
	methods: {
		async getData() {
			const params = {
				limit: 4
			}
			var myDate = new Date(this.calendarTime)
			var timeFrom = myDate.getTime()
			var timeTo = datePicker(timeFrom,0,3,0,'') - 1000
			params.time_from = parseInt(timeFrom / 1000)
			params.time_to = parseInt(timeTo / 1000)
			const calendar = await fetchCampaignCalendar(params)
			this.calendarList = calendar.data
			const tags = await fetchCampaignTags()
			this.tagsOptions = tags.data.list
		},
		getList() {
			this.listLoading = true
			const params = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			if(this.signuptime) {
				params.time_from = parseInt(this.signuptime[0] / 1000)
				params.time_to = parseInt(this.signuptime[1] / 1000)
			}
			fetchCampaignList(params).then(response => {
				const { data } = response
				if(data.list.length > 0 && this.pageCurr > 1) {
					this.campaignList = this.campaignList.concat(data.list)
				} else {
					this.campaignList = data.list
				}
				if(this.campaignList.length < data.count) {
					this.moreFlag = true
				} else {
					this.moreFlag = false
				}
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleViewMore() {
			if(this.moreFlag) {
				this.pageCurr++
				this.getList()
			}
		},
		handleSearchClear() {
			this.pageQuery = Object.assign({},queryDefault)
			this.pageCurr = 1
			this.getList()
		},
		calendarDatePicker(time,month) {
			var newTime = datePicker(time,0,month,0,'')
			return newTime
		},
		handleCalendar(month) {
			var myDate = new Date(this.calendarTime)
			myDate.setMonth(myDate.getMonth() + month)
			this.calendarTime = myDate.getTime()
			this.getData()
		},
		timeDifference(startTime,endTime) {
			const diff = endTime - startTime
			return Math.ceil(diff / 86400)
		},
		signUpStatus(startTime,endTime) {
			const currTime = parseInt(Date.now() / 1000)
			const diff = endTime - currTime
			return Math.ceil(diff / 86400) + this.$t('seller.campaign.tipsText5')
		}
	}
}
</script>

<style lang="scss" scoped>
.calendar-head {
	display: flex;
	.title {
		flex: 1;
		font-size: 18px;
		color: #333333;
	}
	.time {
		flex: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		span {
			color: #666666;
			margin: 0 9px;
		}
		[class^=el-icon-] {
			color: #666666;
			font-weight: bold;
			cursor: pointer;
		}
	}
	.next {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
.calendar-main {
	display: flex;
	.calendar-panel {
		flex-shrink: 0;
		width: calc(33.333% - 8px);
		border-radius: 9px;
		margin-right: 12px;
		border: 1px solid #f0f2f5;
		.panel-head {
			padding: 0 9px;
			height: 32px;
			display: flex;
			opacity: .5;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #f0f2f5;
			.month {
				font-size: 14px;
				font-weight: bold;
				line-height: 32px;
				color: #111111;
			}
			.number {
				padding: 0 6px;
				line-height: 20px;
				font-size: 12px;
				color: #666666;
				border-radius: 24px;
				border: 1px solid #e3e5e7;
			}
		}
		.panel-main {
			height: 288px;
		}
		.panel-list {
			padding: 9px;
			.item {
				padding: 9px;
				margin-bottom: 6px;
				border-radius: 12px;
				background: repeating-linear-gradient(-45deg,#f7f8fa,#f7f8fa 3px,#ebebeb 0,#ebebeb 5px);
				.text {
					margin-bottom: 3px;
					.el-tag {
						margin-right: 6px;
						border-radius: 10px;
						padding: 0 9px;
						border-color: transparent;
						.svg-icon {
							margin-right: 4px;
						}
					}
				}
				.name {
					font-size: 14px;
					line-height: 20px;
					color: #111111;
					a {
						color: inherit;
						&:hover {
							color: $--seller-primary;
						}
					}
				}
				&.active {
					background: #f7f8fa;
				}
			}
			.empty {
				height: 270px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: rgb(204, 204, 204);
			}
		}
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			.panel-head {
				opacity: 1;
			}
		}
	}
}
.activity-list {
	display: flex;
	flex-flow: wrap;
	.item {
		flex-shrink: 1;
		width: calc(50% - 9px);
		padding: 12px;
		border-radius: 12px;
		box-sizing: border-box;
		border: 1px solid #e6e8ed;
		margin-bottom: 18px;
		display: flex;
		flex-direction: column;
		.item-wrap {
			flex-grow: 1;
		}
		.item-head {
			height: 24px;
			margin-bottom: 3px;
		}
		.item-main {
			.name {
				font-size: 16px;
				line-height: 1.5;
				color: #111111;
				margin: 3px 0;
				overflow: hidden;
				display: -webkit-box;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			.text {
				color: #999999;
				font-size: 14px;
				line-height: 20px;
				overflow: hidden;
				display: -webkit-box;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			.time {
				font-size: 14px;
				color: #999999;
				margin: 3px 0;
				line-height: 20px;
				span {
					margin-left: 6px;
				}
			}
		}
		.item-bottom {
			margin-top: 9px;
			padding-top: 12px;
			border-top: 1px solid rgb(230, 233, 237);
			height: 36px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.status {
				font-size: 14px;
				line-height: 20px;
				[class^=el-icon-] {
					margin-right: 6px;
					color: inherit;
					font-weight: bold;
				}
				span {
					color: #999999;
					margin-right: 6px;
					&.primary {
						color: $--seller-primary;
						strong {
							color: inherit;
						}
					}
				}
				strong {
					color: #111111;
				}
			}
		}
		&:hover {
			border-color: $--seller-primary;
			.item-main {
				.name {
					color: #000000;
					font-weight: bold;
				}
				.time {
					span {
						color: #111111;
					}
				}
			}
		}
		&:nth-child(2n) {
			margin-left: 18px;
		}
	}
}
.activity-bottom {
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>